<template>
  <label :class="small ? 'small' : ''">
    <input ref="objCheckbox" type="checkbox"
           :class="small ? 'small':''"
           :checked="checkedLocal"
           :disabled="disabled"
           @input="change($event)"
           @click.stop
    >
    <slot />
  </label>
</template>

<script>
export default {
  name: 'AppCheckbox',
  props: {
    text: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
    },
  },

  data() {
    return {
      checkedLocal: this.checked,
    };
  },

  watch: {
    modelValue(newVal) {
      this.setChecked(newVal);
    },
    checked() {
      this.checkedLocal = this.checked;
    },
  },

  mounted() {
    this.setChecked(this.modelValue);
    if (this.checked) {
      this.setChecked(this.checked);
    }
  },

  methods: {
    change(event) {
      this.$emit('update:modelValue', event.target.checked);
      this.$emit('changeAction', event.target.checked);
      this.setChecked(event.target.checked);
    },
    setChecked(check) {
      this.checkedLocal = check === '1' || check === true || check === 1 || check === 'true';
      this.$refs.objCheckbox.checked = this.checkedLocal;
    },

    getValue() {
      return this.$refs.objCheckbox.checked ? 1 : 0;
    },
  },
};
</script>

<style scoped>
input[type='checkbox']:checked {
  background: #1CA79F;
  outline: none;
  border: 1px solid #1CA79F;
}

label {
  margin: 0 10px 0 0;
  cursor: pointer;
  font-size: 14px;
  position: relative;
  display: flex;
  align-items: center;
}

div {
  display: inline-flex;
  align-items: center;
}

@-moz-document url-prefix() {
  .p-1 th div {
    position: relative;
    top: 5px;
  }
}

input[type='checkbox'] {
  -webkit-appearance: none;
  width: 24px !important;
  height: 24px !important;
  min-width: 24px;
  min-height: 24px;
  display: inline-block;
  background: #FFFFFF;
  border: 1px solid rgba(220, 220, 225, 1);
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;
  padding: 0;
  position: relative;
  top: calc(50% - 12px);

}

.small {
  height: 16px;
  min-height: 20px;
}

input[type='checkbox'].small {
  transform: scale(calc(16 / 24));
  margin: 0px;
  padding: 0px;
  background: transparent;
  border: 2px solid #ADB5BD;
}

input[type='checkbox']:checked {
  background: #1CA79F;
  outline: none;
  border: 1px solid #1CA79F;
}

input[type='checkbox']:checked::after {
  content: '';
  display: block;
  position: relative;
  left: 8px;
  top: 2px;
  width: 6px;
  height: 15px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  -webkit-transform: rotate(45deg);
}

input[type='checkbox'].small::after {
  content: '';
  display: inline-block;
  position: relative;
  left: 7px;
  top: 2px;
  width: 8px;
  height: 15px;
  border-bottom: 3px solid transparent;
  border-right: 3px solid transparent;
  -webkit-transform: rotate(45deg);
}

input[type='checkbox'].small:checked::after {
  content: '';
  display: inline-block;
  position: relative;
  left: 7px;
  top: 2px;
  width: 8px;
  height: 16px;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
  -webkit-transform: rotate(45deg);
}

label.small {
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  font-size: 11px;
  top: -1px;
}

input[type="checkbox"]:disabled {
  background: #E9ECEF;
  border-color: #E9ECEF;
  cursor: no-drop;
}

input[type='checkbox']:disabled:checked::after {
    border-bottom: 2px solid #666666!important;
    border-right: 2px solid #666666!important;
}
</style>
