<template>
  <div :class="(small?'small':'')+(toplabel===''?'maindiv':'main-div-app-input-text')"
       :style="'display: inline-block; '+styleInputText"
  >
    <div v-if="toplabel!=''" class="toplabel">{{ toplabel }}</div>
    <input ref="cutInput"
           v-model="valueLocal"
           :class="(customClass ? customClass : '') + (small ? ' small' : '')"
           type="text"
           :placeholder="placeholder"
           :style="'width:100%;position:relative; top:'+(toplabel!==''?'-1':'0')+'px;'+styleInputText+(disabled?';color:rgba(0,0,0,0.6);':'')"
           :readOnly="readOnly"
           :disabled="disabled"
           autocomplete="off"
           @input="inputAct"
           @change="changeAct"
           @focus="focusAct"
           @click="inputFocus"
           @blur="onBlur"
           @keydown="inputKeyDown"
           @keyup="inputKeyUp"
           @keydown.enter.prevent="enterAction"
    >

    <div :ref="'dtl'+name"
         class="opt_main_new"
         @mouseover="focusedDatalist = true"
         @mouseleave="focusedDatalist = false"
    >
      <ul :ref="'dtu'+name">
        <li v-for="option in listOptions" :key="option" class="opt" @click="clickOpt(option)">{{ option }}</li>
      </ul>
    </div>

    <img v-if="clearbutton && valueLocal!=''" class="clearbutton"
         src="https://images.nav.by/img/cdn/admin/clear-in-input-field.svg" @click="clearField()"
    >

    {{ log }}
  </div>
</template>

<script>
export default {
  name: 'AppInputTextWithDatalist',
  props: {
    modelValue: {
      required: false,
      default: null,
    },
    value: {
      required: false,
      default: null,
    },
    toplabel: {
      required: false,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
    customClass: {
      default: null,
    },
    clearbutton: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      required: false,
    },
    styleInputText: {
      required: false,
    },
    datalist: {
      default: null,
    },
    currentFocus: {
      default: -1,
    },
    name: {
      default: 'field',
    },
    timeout: {
      default: 0,
    },
    saveManual: {
      default: false,
    },
    disabled: {
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      browsers: null,
      changedLeft: false,
      input: null,
      old_value: '',
      saved_old_value: '',
      focusedDatalist: false,
      log: '',
      valueLocal: '',
      listOptions: null,
      activeTimeout: null,
      activeTimeoutChange: null,

      clicked: false,
      datalistLocal: [],
      notShowListNextStep: false,

    };
  },
  watch: {
    datalist(newVal) {
      this.datalistLocal = newVal;
      this.listOptions = this.datalistComp();
      if (this.clicked) {
        this.inputFocus();
      }
      this.focusedDatalist = true;
    },

    valueLocal(newVal) {
      this.$emit('changeAction', newVal);
      this.$emit('update:modelValue', newVal);
    },

    modelValue(newVal) {
      this.valueLocal = newVal;
      this.listOptions = this.datalistComp();
    },
    value(newVal) {
      this.valueLocal = newVal;
      this.listOptions = this.datalistComp();
    },
  },

  created() {
  },

  mounted() {
    this.valueLocal = this.$props.modelValue !== null ? this.$props.modelValue : this.$props.value;
    this.setOpt();
  },

  methods: {
    changeAct(event) {
      event.preventDefault();
      this.$emit('changeAction', event.target.value);
    },
    inputAct(event) {
      event.preventDefault();
    },
    clickOpt(opt) {
      this.old_value = opt;
      this.browsers.setAttribute('style', 'display:none !important');
      this.clicked = false;
      this.notShowListNextStep = true;
      this.setValue(opt);
      this.saveToCookies(true);
    },

    clearField() {
      this.valueLocal = '';
      this.$emit('update:modelValue', '');
    },

    datalistComp() {
      const list = this.getCookieList();
      if (this.datalistLocal != null && this.datalistLocal.length > 0) {
        for (let i = 0; i < this.datalistLocal.length; i++) {
          if (this.datalistLocal[i] !== null && list.indexOf(this.datalistLocal[i].trim()) < 0) {
            list.push(this.datalistLocal[i]);
          }
        }
      }

      if (this.valueLocal !== null && this.valueLocal !== undefined && typeof this.valueLocal === 'string' && this.valueLocal !== '') {
        const res = [];
        const self = this;
        const listWord = (`${self.valueLocal.replaceAll('  ', ' ').trim()} `).toUpperCase().split(' ');
        list.forEach((item) => {
          let searchFlag = false;
          listWord.forEach((item) => {
            if (item.toUpperCase().indexOf(item) > -1) {
              searchFlag = true;
            }
          });
          if (searchFlag) {
            res.push(item);
          }
        });
        return res;
      }
      return list;
    },

    getCookieList() {
      const list = [];

      if (this.name !== 'field') {
        for (let i = 0; i < 10; i++) {
          if (localStorage.getItem(`aitwd_${this.name}_${i}`) != null) {
            if (list.indexOf(localStorage.getItem(`aitwd_${this.name}_${i}`).trim()) < 0) {
              list.push(localStorage.getItem(`aitwd_${this.name}_${i}`).trim());
            }
          }
        }
      }
      return list;
    },

    saveToList() {
      this.saveToCookies(false);
    },

    saveToCookies(autoSave) {
      if ((!this.saveManual && autoSave) || !autoSave) {
        let flag = true;
        const tmpList = this.datalistComp();

        if (tmpList != null && tmpList.length > 0 && this.valueLocal.trim() !== '') {
          for (const item in tmpList) {
            if (this.prepareStr(item).trim().toUpperCase() === this.prepareStr(this.valueLocal).trim().toUpperCase()) {
              flag = false;
            }
          }
        }
        if (flag && this.valueLocal !== null && String(this.valueLocal).trim() !== '') {
          const list = this.getCookieList();
          for (let i = 0; i < list.length; i++) {
            if (this.prepareStr(list[i]).trim().toUpperCase() === this.prepareStr(this.valueLocal).trim().toUpperCase()) {
              flag = false;
            }
          }
        }
        if (flag && this.valueLocal !== null && String(this.valueLocal).trim() !== '') {
          for (let i = 9; i > 0; i--) {
            if (localStorage.getItem(`aitwd_${this.name}_${i}`) != null) {
              localStorage.removeItem(`aitwd_${this.name}_${i}`);
            }
            if (localStorage.getItem(`aitwd_${this.name}_${i - 1}`) != null) {
              const txt = localStorage.getItem(`aitwd_${this.name}_${i - 1}`);
              localStorage.setItem(`aitwd_${this.name}_${i}`, txt);
              localStorage.removeItem(`aitwd_${this.name}_${i - 1}`);
            }
          }
          localStorage.setItem(`aitwd_${this.name}_0`, this.valueLocal);
        }
      }
    },

    prepareStr(val) {
      if (val.replaceAll(/([0-9])/g, '') === '') {
        return val;
      }

      return val.replaceAll(/([0-9])/g, '');
    },

    focusAct() {
      this.clicked = true;

      if (this.activeTimeout) {
        clearTimeout(this.activeTimeout);
      }
    },
    onBlur() {
      if (this.valueLocal !== '' && this.saved_old_value !== this.valueLocal) {
        this.saveToCookies(true);
        this.saved_old_value = this.valueLocal;
      }
      if (this.old_value !== this.valueLocal) {
        this.old_value = this.valueLocal;
        this.$emit('update:modelValue', this.valueLocal);
      }
      this.$emit('blur', this.valueLocal);

      // if(this.clicked || !this.clicked) { //!this.clicked && !this.focusedDatalist
      this.activeTimeout = setTimeout(() => this.onBlurTimeout(), 200);
      // }
    },

    onBlurTimeout() {
      this.clicked = false;
      if (this.activeTimeout) {
        clearTimeout(this.activeTimeout);
      }
      if (this.browsers != null) {
        this.browsers.setAttribute('style', 'display:none !important');// .style.display = 'none!important';
      }
      if (this.$refs.cutInput !== null) {
        this.$refs.cutInput.style.borderRadius = '5px';
      }
    },

    setOpt() {
      if (this.$refs[`dtl${this.name}`] !== undefined) {
        this.browsers = this.$refs[`dtl${this.name}`];// 1
      }
      this.listOptions = this.datalistComp();
    },

    addActive(x) {
      if (!x) return false;
      this.removeActive(x);
      if (this.currentFocus >= x.length) this.currentFocus = 0;
      if (this.currentFocus < 0) this.currentFocus = (x.length - 1);
      x[this.currentFocus].classList.add('active');
      return false;
    },
    removeActive(x) {
      for (let i = 0; i < x.length; i++) {
        x[i].classList.remove('active');
      }
    },

    enterAction() {
      this.$emit('keydown.enter.prevent');
    },

    inputFocus() {
      if (this.activeTimeout) {
        clearTimeout(this.activeTimeout);
      }
      this.datalistLocal = this.datalist;
      this.setOpt();
      this.clicked = true;

      this.old_value = this.valueLocal;
      if (this.browsers !== undefined && (this.listOptions === null || this.listOptions.length > 0)) { // && !this.notShowListNextStep
        this.browsers.setAttribute('style', `display:block !important;width:${this.$el.clientWidth - 10}px !important`);// .style.display = 'block!important';
        this.$refs.cutInput.style.borderRadius = '5px 5px 0 0';
      }

      const self = this;
      setTimeout(() => {
        // eslint-disable-next-line no-underscore-dangle
        const ulHeight = Math.min(self.$refs[`dtu${self.name}`].clientHeight, 240);
        if (window.innerHeight - self.$el.offsetTop - ulHeight < 50) {
          const calcTop = -ulHeight - 32;
          this.browsers.setAttribute('style', `  position: relative !important;top: ${calcTop}px !important;  border-radius: unset !important;  border-top-left-radius: 5px !important;  border-top-right-radius: 5px !important;display:block !important;width:${this.$el.clientWidth - 10}px !important`);// .style.display = 'block!important';
          this.$refs.cutInput.style.borderRadius = '0 0 5px 5px';
        }
      }, 30);
      this.notShowListNextStep = false;
    },

    inputKeyUp(val) {
      if (val.key === 'Enter') {
        this.activeTimeout = setTimeout(() => this.onBlurTimeout(), 200);
      }

      if (this.old_value !== this.valueLocal) {
        this.old_value = this.valueLocal;
        if (this.activeTimeoutChange) {
          clearTimeout(this.activeTimeoutChange);
        }
        this.activeTimeoutChange = setTimeout(() => {
          // this.$emit('update:modelValue', this.old_value);
        }, parseInt(this.timeout, 10));
      }
      this.$emit('keyup', val);
      this.$refs.cutInput.style.borderRadius = '5px';
      this.clicked = true;
    },

    inputKeyDown(e) {
      this.$emit('keydown', e);
      const tmpList = [];// this.datalistComp();
      if (tmpList != null && tmpList.length > 0 && 1 === 2) {
        if (e.keyCode === 40) {
          this.currentFocus++;
          this.addActive(this.browsers.options);
        } else if (e.keyCode === 38) {
          this.currentFocus--;
          this.addActive(this.browsers.options);
        } else if (e.keyCode === 13) {
          e.preventDefault();
          if (this.currentFocus > -1) {
            // if (this.browsers.options) this.browsers.options[this.currentFocus].click();
          }
        }
      }
    },

    focus() {
      // eslint-disable-next-line no-underscore-dangle
      this.$refs[`e${this.name}`].focus();
    },

    setValue(newVal) {
      this.valueLocal = newVal;
      this.$emit('update:modelValue', newVal);
    },
    getValue() {
      return this.$refs.cutInput.value;
    },
    setSelelection(val) {
      this.$refs.cutInput.selectionStart = val;
      this.$refs.cutInput.selectionEnd = val;
    },
    getSelStart() {
      return this.$refs.cutInput.selectionStart;
    },
    getSelEnd() {
      return this.$refs.cutInput.selectionEnd;
    },

  },
};
</script>

<style scoped>
datalist {
  position: absolute;
  background-color: white;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  border-top: none;
  width: 350px;
  padding: 5px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 100;
  text-align: left;
}

option {
  background-color: white;
  padding: 4px;
  color: black;
  margin-bottom: 1px;
  font-size: 14px;
  cursor: pointer;
}

option:hover, .active {
  background: #DFEAFF;
  color: black;
}

input[type='text'].small {
  height: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
}

input[type='text'] {
  max-width: 100%;
  width: 100%;
  display: block;
  height: 30px;
  padding: 12px 10px 6px 10px;
  margin: 2px 0px 6px 0px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

input[type='text']::placeholder {
  color: #ADB5BD;
  font-weight: 400;
}

input[type='text']:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0, 125, 255, 25%);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

datalist::-webkit-scrollbar {
  width: 16px;
  background-color: #FFFFFF;
}

datalist::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #DFE0E1;
  padding: 2px;
  margin: 2px;
  width: 4px;
  border: 2px solid white;
}

datalist::-webkit-scrollbar-track {
  border-radius: 10px;
}

.maindiv {
  position: relative;
  margin: 0px !important;
  padding: 0px;
  width: 100%;
  height: 30px;
}

.maindiv input {
  position: relative;
  margin: 0px !important;
  padding: 0px 5px 0px 5px !important;
  width: 100% !important;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #000000;

}

.opt_main_new ul li {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  display: block !important;;
  padding: 3px 5px !important;;
  text-align: left !important;;
}

.opt_main_new ul li:hover {
  background: #DFEAFF;

}

.opt_main_new ul {
  list-style: none !important;;
  display: inline-block !important;;
  padding: 0;
  margin: 0;
  min-width: 100% !important;;
  vertical-align: top !important;;
  text-align: left !important;;
}

.maindiv .opt_main_new, .small .opt_main_new, .main-div-app-input-text  .opt_main_new{

  position: absolute;
  top: 30px;
  display: none ;
  background: #FFFFFF;
  width: 100% !important;
  max-height: 240px !important;;
  overflow: auto !important;;
  border-radius: unset !important;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  z-index: 50;

  border: 1px solid #ced4da;
  width: -moz-max-content !important;
  width: max-content !important;
  min-width: -webkit-fill-available;

}

.small .opt_main_new {
  top: 24px;
}

.opt_main {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  max-width: 550px;
}

.opt {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  max-width: 550px !important;
  word-wrap: anywhere !important;
  overflow-wrap: break-word !important;
  line-break: anywhere !important;
}

.maindiv .clearbutton, .small .clearbutton {
  position: absolute;
  top: 6px !important;
  left: calc(100% - 30px) !important;
  margin-right: 5px !important;
  zoom: 0.8;
  background-color: white;
  z-index: 10;
}

.small .clearbutton {
  zoom: 0.6;
}

.maindiv .top {
  border: 1px solid red !important;
  position: relative !important;
  top: -270px !important;
  border-radius: unset !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  z-index: 50;

  border: 1px solid #ced4da;
  width: -moz-max-content !important;
  width: max-content !important;
  min-width: -webkit-fill-available;

}

.toplabel {
  background-color: white;
  width: auto !important;
  min-width: 0px!important;
  display: inline-flex;
  position: absolute;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #95A0AA;
  z-index: 1;
  margin-left: 5px;
  margin-top: -7px;
  padding: 0px 3px;
  height: 14px;
  top: 10px;
}

.main-div-app-input-text {
  display: inline-block;
  max-height: 43px;
  padding-top: 7px;
  text-align: left;
  width: 100%;
  position: relative;
}
.main-div-app-input-text .opt_main_new {
  top: 37px !important;
}

.main-div-app-input-text .clearbutton {
  position: absolute;
  top:11px;
  right: 5px;

}
</style>
