<template>
  <multiselect
    :class="((small?' small-view-select': 'my-custom-select') + ( thin ? ' thin' :''))"
    style="position: relative"
    :options="options"
    :multiple="multiple"
    :value="selectedValue"
    :dataid="dataid"
    :loading="loading"
    :deloldopt="deloldopt"
    :label="label"
    :custom-label="customLabel"
    :searchable="searchable"
    :clear-on-select="clearOnSelect"
    :placeholder="placeholder"
    :close-on-select="closeOnSelect"
    :select-label="selectedLabel"
    :selected-label="selectedLabel"
    :deselect-label="deselectLabel"
    :show-labels="showLabels"
    :limit="limit"
    :track-by="trackBy"
    :limit-text="limitText"
    :style="{width: width}"
    :preselect-first="preselectFirst"
    :allow-empty="allowEmpty"
    :group-values="groupValues"
    :group-label="groupLabel"
    :group-select="groupSelect"
    :colored="colored"
    :enable-select-all="enableSelectAll"
    :disabled="disabled"
    @search-change="updateSearchSelect"
    @open="updateStyle"
    @close="close"
    @input-emit="onChange($event)"
  >
    <template #noOptions>
      <slot name="noOptions" />
    </template>

    <template #noResult>
      Не найдено
    </template>

    <template v-if="colored" #option="props">
      <template v-if="props.option[label]">
        <span class="multiselect-colored" :style="'background-color: ' + props.option.color" />
        <span>{{ props.option[label] }}</span>
      </template>
    </template>
  </multiselect>
</template>

<script>
import {isProxy} from 'vue';
import Multiselect from './multipleselect/Multiselect.vue';

export default {
  name: 'AppSelect',
  components: {Multiselect},
  props: {
    resized: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    thin: {
      type: Boolean,
      default: false,
    },

    value: {
      default: null,
    },

    dataid: {
      type: String,
      default: 'id',
    },

    options: {
      required: false,
      default() {
        return [];
      },
    },

    label: {
      type: String,
    },

    customLabel: {
      type: Function,
      required: false,
    },

    searchable: {
      type: Boolean,
      default: false,
    },

    clearOnSelect: {
      type: Boolean,
      default: false,
    },

    placeholder: {
      type: String,
      default: 'Выберите значение',
    },

    closeOnSelect: {
      type: Boolean,
      default: true,
    },

    selectLabel: {
      type: String,
      default: '',
    },

    selectedLabel: {
      type: String,
      default: '',
    },

    deselectLabel: {
      type: String,
      default: '',
    },

    showLabels: {
      type: Boolean,
      default: false,
    },

    limit: {
      type: Number,
      default: 1,
    },

    limitText: {
      type: Function,
      default: (count) => `и еще: ${count}`,
    },

    width: {
      type: String,
      default: '100%',
    },

    preselectFirst: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    trackBy: {
      type: String,
      default: '',
    },

    allowEmpty: {
      type: Boolean,
      default: true,
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    deloldopt: {
      type: Boolean,
      default: false,
    },

    groupValues: {
      type: String,
      default: '',
    },

    groupLabel: {
      type: String,
      default: '',
    },

    groupSelect: {
      type: Boolean,
      default: false,
    },

    colored: {
      type: Boolean,
      default: false,
    },

    enableSelectAll: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedValue: typeof this.value === 'object' || Array.isArray(this.value) ? this.value : (this.options != null ? this.options.find((option) => parseInt(option[this.dataid], 10) === parseInt(this.value, 10)) : ''),
      calc_width: parseInt(this.width, 10),
      needDelFirstElement: false,
    };
  },

  watch: {
    selectedValue() {
      if (!this.isEqual(this.value, this.selectedValue)) {
        this.$emit('changeAction', this.selectedValue == null ? '' : this.selectedValue);
      }
    },
    value() {
      const self = this;
      this.selectedValue = typeof this.value === 'object' || Array.isArray(this.value) ? this.value : (this.options != null ? this.options.find((option) => (JSON.stringify(option[self.dataid]) === JSON.stringify(self.value) || String(option[self.dataid]) === String(self.value))) : '');
      if (!this.isEqual(this.value, this.selectedValue)) {
        this.$emit('changeAction', this.selectedValue == null ? '' : this.selectedValue);
      }
    },
  },

  mounted() {
    this.updateStyle();
  },

  methods: {

    isEqual(obj1, obj2) {
      return (typeof obj1 === 'object' || Array.isArray(obj1) ? (obj1 === null ? null : (this.dataid === undefined || obj1[this.dataid] === undefined ? JSON.stringify(obj1) : obj1[this.dataid])) : obj1) === (typeof obj2 === 'object' || Array.isArray(obj2) ? (obj2 === null ? null : (this.dataid === undefined || obj2[this.dataid] === undefined ? JSON.stringify(obj2) : obj2[this.dataid])) : obj2);
    },

    updateSearchSelect(value) {
      this.$emit('updateSearch', value);
    },

    getStyle(el, styleProp) {
      const camelize = function (str) {
        return str.replace(/\\-(\\w)/g, (str, letter) => letter.toUpperCase());
      };
      if (el.currentStyle) {
        return el.currentStyle[camelize(styleProp)];
      } if (document.defaultView && document.defaultView.getComputedStyle) {
        return document.defaultView.getComputedStyle(el, null).getPropertyValue(styleProp);
      }
      return el.style[camelize(styleProp)];
    },

    onChange(event) {
      this.selectedValue = event;
      if (this.needDelFirstElement && this.deloldopt) {
        this.needDelFirstElement = false;
        event.splice(0, 1);
        this.$emit('inputAction', event);
      } else if (this.deloldopt) {
        const self = this;
        let flag = false;
        event.forEach((item) => {
          if ((JSON.stringify(self.options[0]) === JSON.stringify(item) || JSON.stringify(self.options[0]) === JSON.stringify(item[0]))) {
            flag = true;
          }
        });
        if (flag) {
          this.selectedValue = this.options[0];
          this.needDelFirstElement = true;
        }
      }
    },
    updateStyle() {
      this.needDelFirstElement = false;
      if (this.options != null && this.value != null && (JSON.stringify(this.options[0]) === JSON.stringify(this.value) || JSON.stringify(this.options[0]) === JSON.stringify(this.value[0]))) {
        if (!Array.isArray(this.value) || (Array.isArray(this.value) && this.value.length === 1)) { this.needDelFirstElement = true; }
      }

      this.calc_width = parseInt(this.width, 10);
      const self = this;
      const fontSize = parseInt(self.getStyle(self.$el, 'font-size'), 10);

      if (self.groupLabel === '' && self.resized && this.options != null) {
        this.options.forEach((item) => {
          if (self.calc_width < (item[self.label].length * fontSize) / 1.8 + 45) {
            self.calc_width = Math.round((item[self.label].length * fontSize) / 1.8 + 45);
          }
        });
      }
      self.calc_width = self.resized ? (`${self.calc_width}px`) : self.width;
      if (this.$children) {
        this.$children[0].$refs.list.style.setProperty('width', this.calc_width);

        if (!this.$children[0].isAbove) {
          this.$children[0].$refs.list.style.setProperty('top', '30px');
        } else {
          this.$children[0].$refs.list.style.setProperty('top', '');
        }
      }

      this.$emit('open');
    },

    close() {
      this.$emit('close');
    },

    clearValue() {
      this.selectedValue = '';
      this.$emit('changeAction', '');
    },

    setValue(newVal) {
      if (this.options === null || this.options.length === 0) {
        setTimeout(() => {
          this.selectedValue = this.options != null ? this.options.find((option) => (option[this.dataid]) === newVal) : '';
        }, 500);
      } else {
        this.selectedValue = this.options != null ? this.options.find((option) => (option[this.dataid]) === newVal) : '';
      }
    },

    getValue() {
      return this.selectedValue ? (this.selectedValue[this.dataid] ? this.selectedValue[this.dataid] : this.selectedValue) : null;
    },
  },
};
</script>

<style>
.multiselect.my-custom-select, .thin{
  position: relative;
}

.multiselect.my-custom-select .multiselect__option {
  padding: 5px;
  min-height: 20px;
}

.thin .multiselect__option {
  padding: 3px;
  min-height: 18px;
}

.multiselect.my-custom-select .multiselect__element:not(:first-child):not(:last-child) .multiselect__option,
.thin .multiselect__element:not(:first-child):not(:last-child) .multiselect__option{
  margin: 1px 0;
}

.multiselect.my-custom-select .multiselect__select {
  height: 30px;
  width: 23px;
}

.multiselect.small-view-select .multiselect__select{
  height: 24px;
  width: 20px;
  padding: 0px;
}

.multiselect.small-view-select .multiselect__element {
  font-size: 14px;
}

.multiselect.small-view-select {
  height: 24px;
  min-height: 24px;
}

.multiselect.small-view-select .multiselect__tags,
.thin .multiselect__tags{
  min-height: 24px;
  padding: 2px 20px 0 8px;
  height: 24px;
  border: 1px solid #ced4da;
}

.multiselect.small-view-select .multiselect__placeholder {
  display: block;
  color: #ADB5BD;
  white-space: nowrap;
  padding: 0px;
  font-size: 12px;
  overflow: hidden;
  text-overflow: clip;
  width: 100%;

}

.multiselect.small-view-select .multiselect__single,
.thin .multiselect__single{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  padding: 0px;
  font-weight: 500;
}

.multiselect.my-custom-select .multiselect__tags {
  min-height: 30px;
  padding: 2px 30px 0 3px;
  height: 30px;
  border: 1px solid #ced4da;
  display: flex;
  align-items: baseline;
}

.multiselect.my-custom-select .multiselect__tags .multiselect__input {
  font-size: 14px;
  margin-top: 3px;
  height: 20px;
}

.multiselect.my-custom-select.multiselect {
  min-height: 30px;
  height: 30px;
  font-size: 14px;
  display: inline-block;
  cursor: pointer;
}

.multiselect.my-custom-select .multiselect__strong {
  font-weight: normal;
  white-space: nowrap;
}

.multiselect.my-custom-select .multiselect__placeholder {
  display: block;
  color: #ADB5BD;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  padding-left: 5px;
}

.multiselect.my-custom-select .multiselect__option--highlight,
.multiselect.small-view-select .multiselect__option--highlight {
  background: #DFEAFF;
  color: black;
}

.multiselect.my-custom-select .multiselect__content-wrapper {
  border: 1px solid #ced4da;
  width: max-content !important;
  max-width: 300px;
  min-width: -webkit-fill-available;
}

.multiselect.my-custom-select .multiselect__content-wrapper li span, .thin .multiselect__content-wrapper li span {
  white-space: unset;
}

.multiselect.my-custom-select .multiselect__option--selected,
.multiselect.small-view-select .multiselect__option--selected,
.thin .multiselect__option--selected{
  background: #586ebf;
  color: white;
  font-weight: normal;
}

.multiselect.my-custom-select .multiselect__single,
.thin .multiselect__single{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  padding-top: 2px;
  font-weight: 500;
}

.multiselect.my-custom-select .multiselect__tag{
  background: white;
  color: black;
  margin-bottom: 2px;
  padding: 4px 0 2px 5px;
}

.multiselect.my-custom-select .multiselect__tags-wrap
{
  display: flex;
  max-width: calc(100% - 55px);
}

.multiselect.my-custom-select .multiselect__tags-wrap.multiselect__tags-wrap-nomore {
  max-width: 100%;
}

.multiselect.my-custom-select .multiselect__tag-icon {
  background: #dfeaff;
}

.multiselect.my-custom-select .multiselect__tag-icon:focus, .multiselect__tag-icon:hover {
  background: #586EBF;
}

.multiselect.my-custom-select .multiselect__tag-icon:after {
  color: rgb(56, 70, 118);
}

.multiselect.my-custom-select .multiselect__spinner {
  height: 28px;
  width: 40px;
}

.multiselect.my-custom-select .multiselect__tag-icon,
.multiselect.small-view-select .multiselect__tag-icon,
.thin .multiselect__tag-icon
{
  display: none !important;
}

.multiselect--disabled .multiselect__current, .multiselect--disabled .multiselect__select {
  color: #a6a6a6;
  background: white;
  height: 27px;
  top: 2px;
}

@media (max-width: 992px) {
  .multiselect.my-custom-select .multiselect__tags .multiselect__input {
    font-size: 16px;
  }
}

.multiselect-colored {
  width: 100%;
  height: 26px;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
}
.thin .multiselect__select, .multiselect.small-view-select .multiselect__select{
  padding: 0;
  width: 15px;
  position: absolute;
  z-index: 1;
}
.thin .multiselect__tags, .multiselect.small-view-select .multiselect__tags{
  padding: 2px 17px 0 3px;
  text-overflow: ellipsis;
  position: absolute;
  width: 100%;
}
.thin .multiselect__tag{
  background: white;
  color: black;
  margin-bottom: 2px;
  padding: 4px 0 2px 0px;
}
.thin .multiselect__tags-wrap
{
  display: inline-block;
  max-height: 15px;
  max-width: calc(100% - 55px);
  position: relative;
  top:-2px;
}
.thin .multiselect__single
{
  display: inline-block;
  max-height: 15px;
  max-width: calc(100% + 3px);
  width: calc(100% + 3px);
  position: relative;
  top:-2px;
}
.thin .multiselect__strong strong{
  padding: 0px!important;
  margin: 0px!important;
  height: 5px;
}
.thin .multiselect__strong{
  height: 20px;
  position: relative;
  top:-1px;
  font-weight: normal;
  white-space: nowrap;
  padding: 0px!important;
  margin: 0px!important;
}
.thin .multiselect__content-wrapper {
  border: 1px solid #ced4da;
  width: max-content !important;
  max-width: 300px;
  min-width: -webkit-fill-available;
}

.multiselect.my-custom-select  .multiselect__tag{
  color: #000;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.multiselect.my-custom-select  .multiselect__option{
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
</style>
