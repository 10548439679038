import {createRouter, createWebHistory} from 'vue-router';
import Cookies from 'vue-cookies';
import store from '../store';

const routes = [
  {
    path: '/ui/login',
    meta: {layout: 'empty', title: 'Вход'},
    component: () => import('../views/login/Login.vue'),
  }, {
    path: '/',
    meta: {layout: 'main', title: 'Админка БТС'},
    component: () => import('../views/home/Home.vue'),
  }, {
    path: '/ui/locale_resources',
    meta: {layout: 'main', title: 'Переводы'},
    component: () => import('../views/locale_resources/LocaleResources.vue'),
  }, {
    path: '/ui/system_login',
    meta: {layout: 'main', title: 'Вход в логин клиента'},
    component: () => import('../views/system_login/SystemLogin.vue'),
  }, {
    path: '/ui/autodata',
    meta: {layout: 'main', title: 'Данные по авто'},
    component: () => import('../views/autodata/Autodata.vue'),
    props(route) {
      const params = route.query;
      return {
        url: 'autodata',
        params: Object.keys(params).map((key) => `${key}=${params[key]}`).join('&'),
      };
    },
  }, {
    path: '/ui/show_log10',
    meta: {layout: 'main', title: 'Неоплата / R:NS'},
    component: () => import('../views/show_log10/show_log10.vue'),
    props(route) {
      const params = route.query;
      return {
        url: 'show_log10',
        params: Object.keys(params).map((key) => `${key}=${params[key]}`).join('&'),
      };
    },
  }, {
    path: '/ui/copy_del_auto',
    meta: {layout: 'main', title: 'Перенос/удаление устройств'},
    component: () => import('../views/copy_del_auto/copy_del_auto.vue'),
    props(route) {
      const params = route.query;
      return {
        url: 'copy_del_auto',
        params: Object.keys(params).map((key) => `${key}=${params[key]}`).join('&'),
      };
    },
  }, {
    path: '/ui/test_uvd',
    meta: {layout: 'main', title: 'Наличие авто в радиусе от координаты'},
    component: () => import('../views/test_uvd/test_uvd.vue'),
    props(route) {
      const params = route.query;
      return {
        url: 'test_uvd',
        params: Object.keys(params).map((key) => `${key}=${params[key]}`).join('&'),
      };
    },
  }, {
    path: '/ui/log_change_manager',
    meta: {layout: 'main', title: 'Лог изменения менеджеров'},
    component: () => import('../views/log_change_manager/log_change_manager.vue'),
    props(route) {
      const params = route.query;
      return {
        url: 'log_change_manager',
        params: Object.keys(params).map((key) => `${key}=${params[key]}`).join('&'),
      };
    },
  }, {
    path: '/ui/change_device',
    meta: {layout: 'main', title: 'Замена прибора/удаление истории'},
    component: () => import('../views/change_device/change_device.vue'),
    props(route) {
      const params = route.query;
      return {
        url: 'change_device',
        params: Object.keys(params).map((key) => `${key}=${params[key]}`).join('&'),
      };
    },
  }, {
    path: '/ui/show_log',
    meta: {layout: 'main', title: 'Аналитика по типам устройств'},
    component: () => import('../views/show_log/show_log.vue'),
    props(route) {
      const params = route.query;
      return {
        url: 'show_log',
        params: Object.keys(params).map((key) => `${key}=${params[key]}`).join('&'),
      };
    },
  }, {
    path: '/ui/addobj',
    meta: {layout: 'main', title: 'Добавление объектов в систему'},
    component: () => import('../views/addobj/addobj.vue'),
    props(route) {
      const params = route.query;
      return {
        url: 'addobj',
        params: Object.keys(params).map((key) => `${key}=${params[key]}`).join('&'),
      };
    },
  }, {
    path: '/ui/addusr',
    meta: {layout: 'main', title: 'Добавление нового пользователя'},
    component: () => import('../views/addusr/addusr.vue'),
    props(route) {
      const params = route.query;
      return {
        url: 'addusr',
        params: Object.keys(params).map((key) => `${key}=${params[key]}`).join('&'),
      };
    },
  }, {
    path: '/ui/load_schet',
    meta: {layout: 'main', title: ' Выгрузка счетов'},
    component: () => import('../views/load_schet/load_schet.vue'),
    props: {url: 'load_schet'},
  }, {
    path: '/ui/plan',
    meta: {layout: 'main', title: 'График работ'},
    component: () => import('../views/plan/plan.vue'),
    props: {url: 'plan'},
  }, {
    path: '/ui/check_device3',
    meta: {layout: 'main', title: 'Новый конфигуратор'},
    component: () => import('../views/check_device3/check_device3.vue'),
    props: {url: 'check_device3'},
  }, {
    path: '/ui/config_editor',
    meta: {layout: 'main', title: 'Настройка конфигуратора'},
    component: () => import('../views/config_editor/config_editor.vue'),
    props: {url: 'config_editor'},
  }, {
    path: '/ui/coord_danger',
    meta: {layout: 'main', title: 'Опасные места'},
    component: () => import('../views/coord_danger/coord_danger.vue'),
    props: {url: 'coord_danger'},
  }, {
    path: '/ui/show_log1',
    meta: {layout: 'main', title: 'Анализ трафика'},
    component: () => import('../views/show_log1/show_log1.vue'),
    props(route) {
      const params = route.query;
      return {
        url: 'show_log1',
        params: Object.keys(params).map((key) => `${key}=${params[key]}`).join('&'),
      };
    },
  }, {
    path: '/ui/auto_cpureset',
    meta: {layout: 'main', title: 'Автоматический cpureset'},
    component: () => import('../views/auto_cpureset/autoCpuReset.vue'),
    props(route) {
      const params = route.query;
      return {
        url: 'auto_cpureset',
        params: Object.keys(params).map((key) => `${key}=${params[key]}`).join('&'),
      };
    },
  }, {
    path: '/ui/sklad',
    meta: {layout: 'main', title: 'Склад'},
    component: () => import('../views/sklad/sklad.vue'),
    props(route) {
      const params = route.query;
      return {
        url: 'sklad',
        params: Object.keys(params).map((key) => `${key}=${params[key]}`).join('&'),
      };
    },
  }, {
    path: '/ui/stat_load',
    meta: {layout: 'main', title: 'Статистика'},
    component: () => import('../views/stat_load/stat_load.vue'),
    props(route) {
      const params = route.query;
      return {
        url: 'stat_load',
        params: Object.keys(params).map((key) => `${key}=${params[key]}`).join('&'),
      };
    },
  }, {
    path: '/ui/vopros',
    meta: {layout: 'main', title: 'Вопросник'},
    component: () => import('../views/vopros/Vopros.vue'),
  }, {
    path: '/ui/accounts_client',
    meta: {layout: 'main', title: 'База клиентов'},
    component: () => import('../views/account_client/AccountClient.vue'),
  }, {
    path: '/ui/my_calls',
    meta: {layout: 'main', title: 'Мои Звонки'},
    component: () => import('../views/my_calls/MyCalls.vue'),
    props(route) {
      const params = route.query;
      return {
        url: 'my_calls',
        params: Object.keys(params).map((key) => `${key}=${params[key]}`).join('&'),
      };
    },
  }, {
    path: '/ui/adm_users',
    meta: {layout: 'main', title: 'Сотрудники'},
    component: () => import('../views/adm_users/AdmUsers.vue'),
  }, {
    path: '/ui/edit_news',
    meta: {layout: 'main', title: 'Изменение системной новости'},
    component: () => import('../views/edit_news/news.vue'),
    props: {url: 'edit_news'},
  }, {
    path: '/ui/check_change_device',
    meta: {layout: 'main', title: 'Отчет по замене приборов'},
    component: () => import('../views/check_change_device/CheckChangeDevice.vue'),
    props: {url: 'check_change_device'},
  }, {
    path: '/ui/auto_disable_login',
    meta: {layout: 'main', title: 'Маленькие логины'},
    component: () => import('../views/auto_disable_login/auto_disable_login.vue'),
    props(route) {
      const params = route.query;
      return {
        url: 'auto_disable_login',
        params: Object.keys(params).map((key) => `${key}=${params[key]}`).join('&'),
      };
    },
  }, {
    path: '/ui/rils_group',
    meta: {layout: 'main', title: 'Групповая прошивка'},
    component: () => import('../views/rils_group/rils_group.vue'),
    props(route) {
      const params = route.query;
      return {
        url: 'rils_group',
        params: Object.keys(params).map((key) => `${key}=${params[key]}`).join('&'),
      };
    },
  }, {
    path: '/ui/dev_type',
    meta: {layout: 'main', title: 'Добавление типа устройств'},
    component: () => import('../views/dev_type/dev_type.vue'),
    props: {url: 'dev_type'},
  }, {
    path: '/ui/fuel_setting',
    meta: {layout: 'main', title: 'Настройки объектов'},
    component: () => import('../views/fuel_setting/fuel_setting.vue'),
    props(route) {
      const params = route.query;
      return {
        url: 'fuel_setting',
        params: Object.keys(params).map((key) => `${key}=${params[key]}`).join('&'),
      };
    },
  }, {
    path: '/ui/client_auto',
    meta: {layout: 'main', title: 'Автомобили по клиентам'},
    component: () => import('../views/client_auto/ClientAuto.vue'),
    props: {url: 'client_auto'},
  }, {
    path: '/ui/email_log',
    meta: {layout: 'main', title: 'Лог почтовой рассылки'},
    component: () => import('../views/email_log/email_log.vue'),
    props: {url: 'email_log'},
  }, {
    path: '/ui/configuration_graph',
    meta: {layout: 'main', title: 'Настройка графиков + кодов'},
    component: () => import('../views/configuration_graph/configuration_graph.vue'),
    props(route) {
      const params = route.query;
      return {
        url: 'configuration_graph',
        params: Object.keys(params).map((key) => `${key}=${params[key]}`).join('&'),
      };
    },
  }, {
    path: '/ui/translation_statistics',
    meta: {layout: 'main', title: 'Статистика переводов'},
    component: () => import('../views/translation_statistics/translation_statistics.vue'),
    props: {url: 'translation_statistics'},
  }, {
    path: '/ui/meeting_room_reservation',
    meta: {layout: 'main', title: 'Бронирование переговорок'},
    component: () => import('../views/meeting_room_reservation/MeetingRoomReservation'),
  }, {
    path: '/ui/log_info',
    meta: {layout: 'main', title: 'Лог'},
    component: () => import('../views/log_users/LogUsers.vue'),
    props(route) {
      const params = route.query;
      return {
        url: 'configuration_graph',
        params: Object.keys(params).map((key) => `${key}=${params[key]}`).join('&'),
      };
    },
  }, {
    path: '/ui/analytics',
    meta: {layout: 'main', title: 'Аналитика'},
    component: () => import('../views/analytics/Analytics.vue'),
    props: {url: 'analytics'},
  }, {
    path: '/ui/academy_bts',
    meta: {layout: 'main', title: 'Академия БТС'},
    component: () => import('../views/academy_bts/Academy.vue'),
  }, {
    path: '/ui/dev',
    meta: {layout: 'main', title: 'Выгрузка таблиц из БД'},
    component: () => import('../views/dev/Dev.vue'),
  }, {
    path: '/ui/traffic_ban',
    meta: {layout: 'main', title: 'Запреты'},
    component: () => import('../views/traffic_ban/TrafficBan'),
  }, {
    path: '/ui/load_schet_file',
    meta: {layout: 'main', title: 'Выгрузка счетов через файл'},
    component: () => import('../views/load_schet_file/load_schet.vue'),
    props: {url: 'load_schet_file'},
  }, {
    path: '/ui/system_update_news',
    meta: {layout: 'main', title: 'Обновления системы'},
    component: () => import('../views/system_update_news/SystemUpdateNews.vue'),
  }, {
    path: '/ui/toll_roads',
    meta: {layout: 'main', title: 'Обновление стоимости платных дорог'},
    component: () => import('../views/toll_roads/TollRoads.vue'),
    props: {url: 'toll_roads'},
  }, {
    path: '/ui/call_analysis',
    meta: {layout: 'main', title: 'Анализ звонков'},
    component: () => import('../views/calls/Calls.vue'),
  }, {
    path: '/ui/log',
    meta: {layout: 'main', title: 'Лог'},
    component: () => import('../views/log/Log.vue'),
  }, {
    path: '/ui/test_statistics',
    meta: {layout: 'main', title: 'Статистика по пробегам'},
    component: () => import('../views/company_rating/company_rating.vue'),
    props: {url: 'test_statistics'},
  }, {
    path: '/ui/api',
    meta: {layout: 'main', title: 'API'},
    component: () => import('../views/swagger_static/SwaggerStatic.vue'),
  }, {
    path: '/ui/api_scribe',
    meta: {layout: 'main', title: 'API Scribe'},
    component: () => import('../views/swagger_scribe/SwaggerScribe.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  let token = Cookies.get('token');
  if (token) {
    store.dispatch('fetchPages').then(() => {
      if (store.getters.getPages) {
        store.getters.getPages.some((record) => record.address === `${to.path.replace('/ui', 'admin')}.php`) ? next() : next('/');
      }
    }, (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
    });
  }

  const requireAuth = to.matched.some((record) => record.meta.layout === 'main');
  // для доступа к некоторым станицам по токену без сессии
  if (location.hash) {
    const hashValues = location.hash.substr(1).split('&').reduce((result, item) => {
      const parts = item.split('=');
      result[parts[0]] = parts[1];
      return result;
    }, {});
    if (hashValues.token) {
      token = hashValues.token;
      Cookies.set('token', token, '1d');
    }
  }
  requireAuth && !token ? next('/ui/login') : next();
  document.title = to.meta.title || '';
});

export default router;
