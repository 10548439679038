<template>
  <div class="main-container">
    <div id="bee-plugin-container" />
    <div v-if="showLoadMessage" class="waiting-message">Ожидайте загрузки редактора...</div>
  </div>
</template>

<script>
import axios from 'axios';
import Bee from '@mailupinc/bee-plugin';
import {isProxy, toRaw} from 'vue';

export default {
  name: 'AppBee',
  props: {
    template: {
      default: '',
      required: true,
    },
  },
  data() {
    return {
      info: null,
      showLoadMessage: true,
    };
  },
  mounted() {
    const beeInstance = new Bee();
    let bee;
    const endpoint = 'https://auth.getbee.io/apiauth';
    const payload = {
      client_id: 'dd80d990-c681-4526-88ca-9a19737ea7b7',
      client_secret: 'vU7jd2RGL9P3gBlbn9esoeN7X9Gqqv6FvkrgHy94VyyBEtif7EJx', // Enter your secret key
      grant_type:
          'Basic VGhpc0lzTXlTdXBlckxvbmdUZXN0VXNlcm5hbWU6VGhpc1Bhc3N3b3JkSXNBTGll', // Do not change
    };

    axios.post(endpoint, payload).then((data) => {
      const token = data;
      const config = {
        uid: token.data.userName,
        container: 'bee-plugin-container',
        language: 'ru-RU',
        onSave: (jsonFile, htmlFile) => {
          this.$emit('onSave', {template: jsonFile, html: htmlFile});
        },
        onSend: (html) => {
          this.$emit('onSend', {html});
        },
        onChange: (jsonFile, response) => {
          if (response.code === '0100' || response.code === '0300') {
            try {
              const template = JSON.parse(jsonFile);
              for (let i = 0; i < template.page.rows.length; ++i) {
                const row = template.page.rows[i];
                for (let j = 0; j < row.columns.length; ++j) {
                  const column = row.columns[j];
                  for (let k = 0; k < column.modules.length; ++k) {
                    const module = column.modules[k];
                    if (module.descriptor.text && module.descriptor.text.style.color === '#555555') {
                      module.descriptor = {
                        computedStyle: {
                          hideContentOnMobile: false,
                        },
                        style: {
                          'padding-bottom': '15px',
                          'padding-left': '15px',
                          'padding-right': '15px',
                          'padding-top': '15px',
                        },
                        text: {
                          computedStyle: {
                            linkColor: '#0068A5',
                          },
                          html: '<div class="txtTinyMce-wrapper" style="font-size: 14px; line-height: 21px;" data-mce-style="font-size: 14px; line-height: 21px;"><p style="line-height: 18px; word-break: break-word;" data-mce-style="line-height: 18px; word-break: break-word;"><span style="font-size: 17px; line-height: 25px;" data-mce-style="font-size: 17px; line-height: 25px;">Текст</span></p></div>',
                          style: {
                            color: '#28222b',
                            'font-family': 'inherit',
                            'line-height': '150%',
                          },
                        },
                      };
                    } else if (module.descriptor.button && module.descriptor.button.style['background-color'] === '#3AAEE0') {
                      module.descriptor = {
                        button: {
                          href: '',
                          label: '<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 14px;" data-mce-style="font-size: 12px; line-height: 14px;"><p style="font-size: 16px; line-height: 19px; word-break: break-word;" data-mce-style="font-size: 16px; line-height: 19px; word-break: break-word;"><strong>Подробнее</strong></p></div>',
                          style: {
                            'background-color': '#223f7b',
                            'border-bottom': '0px solid transparent',
                            'border-left': '0px solid transparent',
                            'border-radius': '5px',
                            'border-right': '0px solid transparent',
                            'border-top': '0px solid transparent',
                            color: '#ffffff',
                            'font-family': 'inherit',
                            'line-height': '120%',
                            'max-width': '100%',
                            'padding-bottom': '10px',
                            'padding-left': '20px',
                            'padding-right': '20px',
                            'padding-top': '10px',
                            width: 'auto',
                          },
                        },
                        computedStyle: {
                          height: 39,
                          hideContentOnMobile: false,
                          width: 128,
                        },
                        style: {
                          'padding-bottom': '15px',
                          'padding-left': '15px',
                          'padding-right': '15px',
                          'padding-top': '15px',
                          'text-align': 'left',
                        },
                      };
                    }
                  }
                }
              }
              bee.load(template);
            } catch (e) {
              console.error('Bee: ', e);
            }
          }
        },
      };

      const template = isProxy(this.template) ? toRaw(this.template) : this.template; // Any valid template, as JSON object

      beeInstance
        .getToken(payload.client_id, payload.client_secret)
        .then(() => beeInstance.start(config, template).then(() => {
          this.showLoadMessage = false;
          console.log('hide message');
        }));
    });
  },
};
</script>
<style scoped>
.main-container{
  height: 100%;
  width:100%;
}
.main-container div{
  height: 100%;
  width:100%;
}
.waiting-message{
  position: absolute;
  top:20px;
  display: flex;
  width: calc(100% - 20px);
  height: 100%;
  justify-content: center;
  align-items: center;
}
</style>
